
/* eslint-disable */
import { mapMutations, mapGetters } from "vuex";
import configVars from "@/config/config_vars";
//import facebook from '@/api/facebook' 
export default {
  name: "signin",
  components: {},
  computed: mapGetters({
    user: "user/user",
    isAutenticated: "user/isAutenticated",
    imgSrc: "user/imgSrc",
    getperformersToSave: "user/getperformersToSave",
    getSeleccionados: "user/getSeleccionados",
    activePromoCode: "checkout/activePromoCode"
  }),
  data() {
    return {
      titulo: "Sign In",
    
      senderId: this.$route.query.senderId,
      terms_of_service: configVars.terms_of_service,
      privacy_policy: configVars.privacy_policy,
      valid: true,
      emailErrors: [],
      passwordErrors: [],
      passwordRules: [v => !!v || "Password is required"],
      passwordConfirmation: "",
      passwordConfirmationRules: [
        v => {
          if (this.toogleSignUp == true) {
            if (v == undefined || v == "") {
              return "Password confirmation is required";
            } else {
              if (this.password != v) {
                return "Password confirmation no matches";
              } else {
                return true;
              }
            }
          } else {
            return true;
          }
        }
      ],
      emailRules: [
        v => !!v || "E-mail is required",
        v => {
          var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (re.test(String(v).toLowerCase())) {
            return true;
          } else {
            return "E-mail must be valid";
          }
        }
      ],

      firstNameRules: [
        v => {
          if (this.toogleSignUp == true) {
            if (v == undefined || v == "") {
              return "First Name is required";
            } else {
              return true;
            }
          } else {
            return true;
          }
        }
      ],

      lastNameRules: [
        v => {
          if (this.toogleSignUp == true) {
            if (v == undefined || v == "") {
              return "Last Name is required";
            } else {
              return true;
            }
          } else {
            return true;
          }
        }
      ],

      fbSignInParams: {
        //scope: "email, user_likes, user_birthday, user_friends",
        //scope: "email, user_likes, user_birthday",
        scope: "email, user_likes",
        return_scopes: true
      },

      firstName: "",
      lastName: "",
      email: "",
      password: "",
      response: null,
      rememberMe: false,
      snackbar: false,
      y: "bottom",
      x: "right",
      mode: "",
      timeout: 6000,
      text: "Hello, I'm a snackbar",

      alert: false,
      alertMessage: "",
      alertColor: "",
      alertType: "success",
      toogleSignUp: true,
      recoveryToogle: false,
      recoverySendEmail: false
    };
  },
  props: {
    performerName: {
      type: String
    },

 
    type: {
      type: String,
      default: "promo",
      enum: ["promo", "black-friday"],
    },
  

    
  },
  mounted() {
    //facebook.facebook()
    document.addEventListener("keydown", e => {
      if (e.keyCode == 27) {
        this.close();
      }
    });
  },
  watch: {
    email: function(newVal) {
      this.emailErrors = [];
    },
    password: function(newVal) {
      this.passwordErrors = [];
    },
    toogleSignUp: function(newVal) {
      if (newVal) {
        this.titulo = "Sign Up";
      } else {
        this.titulo = "Sign In";
      }
      this.clear();
    }
  },
  methods: {

    backgroundImageUrl(imageUrl ){
      return      {
      
        'background': `url('${imageUrl}') no-repeat center`,
       
      }
    }, 


    terminosClick() {
    
      location.href = configVars.terms_of_service;
    },
    privacyClick() {
      window.location.replace(configVars.privacy_policy);
    },
    emailClick() {
      this.emailErrors = [];
    },
    passwordClick() {
      this.passwordErrors = [];
    },
    clear() {
      this.$refs.form.reset();
    },
    onSignInSuccess(response) {
      FB.api(
        "/me?fields=id,picture.width(720).height(720),name,first_name,last_name,birthday,email,address,music,favorite_teams,about",
        dude => {
          
          const data = dude;

          let music = [];
          let friends = [];
          //friends = data.friends.data;
          if (friends.length > 0) {
            
          } else {
            
          }
          if (data.music) {
            for (let i = 0; i < data.music.data.length; i++) {
             
              music.push(data.music.data[i].name);
            }
          } else {
          }
    

          this.imgSrc = data.picture.data.url;

       

          this.$store
            .dispatch("user/signup", {
              firstName: data.first_name,
              lastName: data.last_name,
              email: data.email,
              password: data.email,
              birthday: data.birthday,
              imgSrc: data.picture.data.url,
              music: music,
              senderId: this.senderId,
              fbId: data.id,
              tipo: "faceboo",
              fbFriendsIds: friends
            })
            .then(() => {
              this.$store.dispatch(
                "user/setUserSeleccionados",
                this.getSeleccionados
              );

              let user = this.$store.getters["user/user"];
             
              if (user) {
                let coordinates = this.$store.getters["coordinates"];
                if (coordinates) {
                  if (coordinates.lat && coordinates.lon) {
                   
                    this.$store.dispatch(
                      "user/setUserCoordinates",
                      coordinates
                    );
                  }
                }
              }

              if (this.getperformersToSave) {
                this.$store
                  .dispatch("user/setUserPerformers", this.getperformersToSave)
                  .then(response => {
                  
                  })
                  .catch(error => {
                   
                  });
              }
              this.$router.push(`/get_promo/${this.performerName}/${data.first_name}/${data.last_name}/${ data.email}`);

              this.close();
            });
        }
      );
    },
    onSignInError(error) {
      
    },

    close() {
      this.$emit("close");
      this.email = "";
      this.password = "";
      this.alert = false;
      this.firstName = "";
      this.lastName = "";
    },
    openSignUp() {
      this.toogleSignUp = !this.toogleSignUp;
      this.email = "";
      this.password = "";
      this.alert = false;
      this.firstName = "";
      this.lastName = "";
    },

    onSubmit() {
      const formData = {
        email: this.email,
        password: this.password
      };
      
    },

    authLogin: function() {
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch("user/signin", {
            email: this.email,
            password: this.password,
            senderId: this.senderId
          })
          .then(() => {
            this.$store
              .dispatch("user/setUserSeleccionados", this.getSeleccionados)
              .then(() => {});

            if (this.getperformersToSave) {
              this.$store
                .dispatch("user/setUserPerformers", this.getperformersToSave)
                .then(response => {
                 
                })
                .catch(error => {
                 
                });
            }

            this.close();
            this.$router.push(`/get_promo/${this.performerName}/${this.firstName}/${this.lastName}/${this.email}`);
          })
          .catch(error => {
           
            this.emailErrors = [`Couldn't find your account`];
            this.passwordErrors = [`Wrong password`];
            /*this.alert = true;
                        this.alertMessage = "Invalid user credentials";
                        this.alertColor = "red";
                        this.alertType = "error";*/
          });
      }
    },

    authRegister: function() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch("user/email_exists", this.email).then(res => {
          this.$store
            .dispatch("user/signup", {
              firstName: this.firstName,
              lastName: this.lastName,
              email: this.email
            })
            .then(() => {
              this.$store.dispatch(
                "user/setUserSeleccionados",
                this.getSeleccionados
              );

              let user = this.$store.getters["user/user"];

              if (user) {
                let coordinates = this.$store.getters["coordinates"];

                if (coordinates) {
                  if (coordinates.lat && coordinates.lon) {
                  
                    this.$store.dispatch(
                      "user/setUserCoordinates",
                      coordinates
                    );
                  }
                }
              }

              this.$router.push(`/get_promo/${this.performerName}/${this.firstName}/${this.lastName}/${this.email}`);
              this.close();
            });
        });
      }
    },

    recoveryPassword() {
      this.recoverySendEmail = true;
      this.recoveryToogle = true;
      this.$store
        .dispatch("user/recoveryPassword", this.email)
        .then(response => {
         
        });
    }
  }
};
