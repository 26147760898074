
/* eslint-disable */

 

import { mapMutations, mapGetters } from "vuex";

export default {
  name: "PromoRegisterBanner",
  components: {},
  computed: {
    ...mapGetters({
      activePromoCode: "checkout/activePromoCode"
    }),
  },
  data() {
    return {
      promoName :"",

      linearGradient: "purple",
      backgroundImage:
        "" /*"/img/get_promo_code/blackfriday-1.png" */,
    };
  },
  props: {
    type: {
      type: String,
      default: "promo",
      enum: ["promo", "black-friday"],
    },
  },
  async created() {
    switch (this.type) {
      case "promo": {
        this.linearGradient = "purple";
        //this.backgroundImage = "/img/modal_promo_img.jpg";
        break;
      }

      case "black-friday": {
        this.linearGradient = "black";
        //this.backgroundImage = "/img/get_promo_code/blackfriday-promo.jpg";
        break;
      }

      default: {
        this.linearGradient = "purple";
        //this.backgroundImage = "/img/modal_promo_img.jpg";
        
        break;
      }
    } 




  },

  mounted() {
    //facebook.facebook()
    document.addEventListener("keydown", (e) => {
      if (e.keyCode == 27) {
        this.close();
      }
    });

    this.$nextTick(async () => {
    
      this.verifyPromoCodeBD()
 
    });
  },
  watch: {
    activePromoCode(newVal){
      this.verifyPromoCodeBD()
    }
  },
  methods: {
    backgroundImageUrl() {
      return `background: url('${this.backgroundImage}') no-repeat center center / cover; background-size: cover;` 
    },
    getLinearGradient() {
      switch (this.linearGradient) {
        case "purple": {
          return {
            background: `linear-gradient(
                            to right,
                            rgba(71, 11, 75, 0.95),
                            rgba(178, 64, 163, 0.1)
                            )`,
          };
        }

        case "black": {
          return {
            background: `linear-gradient(
                        to right,
                        #000000 46.94%,
                        rgba(0, 0, 0, 0.736842) 54.38%,
                        rgba(0, 0, 0, 0) 95.94%
                        )`,
          };
        }

        default:
          return {
            background: `linear-gradient(
                            to right,
                            rgba(71, 11, 75, 0.95),
                            rgba(178, 64, 163, 0.1)
                            )`,
          };
      }
    },
    verifyPromoCodeBD (){
      this.promoName = this.activePromoCode?.name; //name
      let  bannerUrl = this.activePromoCode?.bannerUrl
      if( bannerUrl ){
        this.backgroundImage= bannerUrl 
      }else{
        this.backgroundImage= 'https://firebasestorage.googleapis.com/v0/b/pepperbussines.appspot.com/o/bannerPromoCodes%2Fmodal_promo_img.jpg?alt=media&token=69828c5b-a471-4ff3-a041-33e4943ae6fb'
      }     
      
    },


    close() {
      this.$emit("close");
    },
  },
};
