
/* eslint-disable */

import configVars from "@/config/config_vars";
import microformatos from "@/api/microformatos";
import events from '@/api/events'
import performers from '@/api/performers'

let appFrontUrl = configVars.appFrontUrl;
import SpeedkitPicture from 'nuxt-speedkit/components/SpeedkitPicture';


export default {
  name: "EventCardG",
  computed: {


  },
  components: {
    SpeedkitPicture
  },
  data() {
    return {
      picture: {
        placeholders: [
          {
            format: 'jpg',
            url: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAzIDInPjwvc3ZnPg=='
          }
        ],
        sources: [

          {
            format: 'jpg',
            sizes: [
              // jpg
              { width: 414, url: `https://firebasestorage.googleapis.com/v0/b/peppertix-org.appspot.com/o/${this.event.performances?.performer?.slug}%2Fthumb%40target.jpg?alt=media&token=ffdad2eb-c055-49d2-b56d-312476a58fa6` }
            ]
          }
        ]
      },
      invitation_code: '',
      appFrontUrl: appFrontUrl,
      factorFees: configVars.factorFees,
      factorFeesIncreased: configVars.factorFeesIncreased,
      loadingClass: `loading_concert`
    };
  },
  props: {
    isFavorite: false,
    index: Number,
    event: {
      type: Object,
      default: {
        performances: [
          {
            performer: {
              slug: '',
              name: ''
            }
          }
        ],
        venue: {
          name: '',
          location: '',
        },
        occurs_at_format: '',
        isFavorite: false,
      }

    },

  },
  created() {
    let { loadingClass } = events.loadingCSSClass(this.event)
    this.loadingClass = loadingClass
  },

  mounted() {

    this.$nextTick(async () => {
      if (!this.event.isFavorite) {
        this.event.isFavorite = false;
      }

      setTimeout(() => {
        var images = document.querySelectorAll('img');
        for (var i = 0; i < images.length; i++) {
          images[i].onerror = function () {
            this.style.display = 'none';
          }
        }



      }, 0)


    })
  },
  watch: {

  },
  methods: {
    async favoriteClick(event, index) {
      return this.$emit('favoriteClick')
    },

    getPrice(event) {
      let min_price_s = ``;

      if (event.min_price) {
        let min_price_f = parseFloat(event.min_price / (1 + this.factorFees)).toFixed(0);
        min_price_s = `$${min_price_f}`;
        return min_price_s;
      }


      return min_price_s;
    },

    getPriceIncreased(event) {

      let min_price_s = ``;
      if (event.min_price) {
        let min_price_f = parseFloat(event.min_price / (1 + this.factorFees)).toFixed(0);
        min_price_f = parseFloat(this.factorFeesIncreased * min_price_f).toFixed(0);
        min_price_s = `$${min_price_f}`;
        return min_price_s;
      } else

        return min_price_s;
    },





    toBook(event) {

    },
    getRuta(event) {
      return microformatos.getRuta(event);
    },
    errorEventImage() {

      performers.updatePerformerById(this.event.performances.performer.id).then((res) => {

      }).catch(error => { })
    },
  }
};
