
/* eslint-disable */
//https://codepen.io/edward1995/pen/MOKdXO?editors=1010

function replaceAll(str, find, replace) {
  return str.replace(new RegExp(find, "g"), replace);
}

function generateSortFn(prop, reverse) {
  return function (a, b) {
    if (a[prop] < b[prop]) return reverse ? 1 : -1;
    if (a[prop] > b[prop]) return reverse ? -1 : 1;
    return 0;
  };
}



import Vue from "vue";
import configVars from "@/config/config_vars";
let appFrontUrl = configVars.appFrontUrl;
import api from "@/api/api";
import pushIfNew from "@/util/push_if_new"





import { mapMutations, mapGetters } from "vuex";

import Cookie from "js-cookie";
import events from "@/api/events";
import microformatos from "@/api/microformatos";
import categories from "@/api/categories";
import cities from "@/api/cities";
import momentFunctions from '@/util/momentFunctions'
import { loadavg } from "os";
import scroll from '@/util/scroll'

//https://github.com/nuxt/nuxt.js/issues/978 <<--ejemplo de 2 requests en paralelo
export default {
  middleware: ["check-sender"],
  async asyncData(context) {

    let ip = "";
    let visto = true;
    let numVistas = 0;
    let numVistasHwi = 0;
    let numVistasMovil = 0;
    if (context.req) {
      ip = context.req.headers["x-forwarded-for"];
      if (ip) {
        var list = ip.split(",");
        ip = list[list.length - 1];
      } else {
        ip = context.req.connection.remoteAddress;
      }


      context.store.commit("storeIP", ip);

      if (context.req.headers.cookie != undefined) {
        let stringCookies = context.req.headers.cookie.split(";");

        if (stringCookies != undefined) {
          let stringNumVistas = stringCookies.find(c =>
            c.trim().startsWith("numVistas=")
          );

          if (stringNumVistas != undefined) {
            let valueNumVistas = stringNumVistas.split("=")[1];

            if (valueNumVistas != undefined) {
              if (!isNaN(valueNumVistas)) {
                numVistas = Number(valueNumVistas) + 1;
              } else {
              }
            }
          }

          let stringNumVistasHwi = stringCookies.find(c =>
            c.trim().startsWith("numVistasHwi=")
          );

          if (stringNumVistasHwi != undefined) {
            let valueNumVistas = stringNumVistasHwi.split("=")[1];

            if (valueNumVistas != undefined) {
              if (!isNaN(valueNumVistas)) {
                numVistasHwi = Number(valueNumVistas) + 1;
              } else {
              }
            }
          }
        }
      }

      context.store.commit("storeNumVistas", numVistas);
      context.store.commit("storeNumVistasHwi", numVistasHwi);
    } else if (process.browser) {

      /**
       * ==========================
       *  store num vistas
       * ==========================
       */
      context.store.commit(
        "storeNumVistas",
        context.store.getters.numVistas + 1
      );

      numVistas = context.store.getters.numVistas + 1;
      var inFifteenMinutes = new Date(new Date().getTime() + 1 * 100);

      Cookie.set("numVistas", context.store.getters.numVistas, {
        expires: inFifteenMinutes
      });

      /**
       * ==========================
       *  store num hwi
       * ==========================
       */
      context.store.commit(
        "storeNumVistasHwi",
        context.store.getters.numVistas + 1
      );

      numVistasHwi = context.store.getters.numVistasHwi + 1;
      var inFifteenMinutes = new Date(
        new Date().getTime() + 1 * 1000 * 60 * 60 * 24 * 365
      );

      Cookie.set("numVistasHwi", context.store.getters.numVistasHwi, {
        expires: inFifteenMinutes
      });

      ip = context.store.getters.IP;
      if (ip != "") {
        localStorage.setItem("ip", ip);
      }
      if (localStorage.getItem("ip") != "") {
        ip = localStorage.getItem("ip");
      }
    }

    if (numVistas > 0) {

      visto = true;
    } else {

      visto = false;
    }

    let toogleIntroSplashHwi = true;
    if (numVistasHwi > 0) {
      toogleIntroSplashHwi = false;
    }


    //visto = true;
    //ip = "136.0.16.217"; san francisco
    //ip ="	187.136.82.151" merida
    let order_by = context.query.order_by

    let queryBase = {};
    let bannerTitulo = "Category";
    let bannerSlug = undefined;
    let categoriaTitulo = "Concerts";
    let loadingImage = "loading_sport";
    let loadingClass = "loading_sport";
    let category_id = 54;
    let dropdown_no_mobile = true;
    let parent_id = 54;
    let parent_slug = undefined;
    let parent = context.params.parent;
    let category_city = context.params.category_city;
    let city = context.params.city;
    let latC = 0;
    let lonC = 0;
    let locality = "North America";
    let cambiarLocality = false;
    let ciudadEncontrada = false;
    let showCategorias = true;
    let categoriasDropDown = [];
    let titleCategoriasDropDown = "";
    let nflConferencias = [];
    let bannerImageUrl = "";
    let bannerImageUrlMobil = "";
    let bannerImageUrlTablet = "";
    let categoriaBuscada = {
      name: 'Concerts'
    };
    let search = parent;
    let lat = 0
    let lon = 0


    search = search.replace(/-/gi, " ");
    search = search.replace(/  /gi, " ");

    search = search.toLowerCase().replace(/\b[a-z]/g, function (letter) {
      return letter.toUpperCase();
    });

    let category = {}

    /*try {
      //let res  =  await context.$axios.$get('https://peppertix-backend.herokuapp.com/tevo/events/query2/?occurs_at.gte=2021-03-18&occurs_at.lt=2021-03-31&only_with_available_tickets=true&order_by=events.occurs_at%20ASC&page=3&per_page=100')
      let res  =  await context.$axios.$get('/tevo/events/query2/?occurs_at.gte=2021-03-18&occurs_at.lt=2021-03-31&only_with_available_tickets=true&order_by=events.occurs_at%20ASC&page=3&per_page=100')
      console.log(`res====================================>> ${res.length}`)
    } catch (error) {
       console.log(error.response.data)
       try {
          onsole.log(error.response.data)
       } catch (error) {
         
       }
  
      console.log(`Error axios ====================================>>  ${error} <<<====================================>> `)
 
    
    }*/


    if (!category_city) {
      console.log('case 1')
      try {
        let [categoryParentRes, ipLocationRes] = await Promise.all([
          categories.getCategoryByName(parent, "true"),
          api.getLocationInfo(ip),
        ])

        if (categoryParentRes.data.length > 0) {

          category = categoryParentRes.data[0];
          if (category) {

            categoriaBuscada = category;
            parent_id = category.id;
            parent_slug = category.slug;
            category_id = category.id;
            bannerTitulo = "Category";

            if (category.parent_name != undefined) {
              bannerTitulo = category.parent_name;
              bannerSlug = category.parent_slug;
            }
            categoriaTitulo = category.name;

          } else {
            console.log(`No tengo categoria ... error case 1`)
          }


        }



        if (ipLocationRes.data) {
          lat = ipLocationRes.data.latitude;
          lon = ipLocationRes.data.longitude;
        }


      } catch (error) {

        console.log('Error Case 1 ' + error)
      }



    } else {
      console.log('case 2')
      try {
        let [categoryParentRes, categoryChildRes, cityRes, ipLocationRes] = await Promise.all([
          categories.getCategoryByName(parent, "true"),
          categories.getCategoryByName(category_city, "true"),
          cities.searchCities({
            slug: category_city,
            page: 1,
            limite: 1
          }),

          api.getLocationInfo(ip),
        ])
        
        if (categoryParentRes.data.length > 0) {
          category = categoryParentRes.data[0];
          
          categoriaBuscada = category;
          category_id = category.id;
          parent_id = category.id;
        }

      
        if (categoryChildRes.data.length > 0) {

          category = categoryChildRes.data[0];
          
          categoriaBuscada = category;
          category_id = category.id;
          parent_id = category.id;

          if (category.parent_id != undefined) {
            //parent_id = category.parent_id;
            parent_slug = category.parent_slug;
            bannerTitulo = category.parent_name;
            bannerSlug = category.parent_slug;
          }


          //bannerTitulo = categoriaTitulo;
          categoriaTitulo = category.name;

        }
        if (cityRes[0] != undefined) {
          latC = cityRes[0].lat;
          lonC = cityRes[0].lon;
          ciudadEncontrada = true;
          locality = cityRes[0].city2;
        }


        lat = ipLocationRes.data.latitude;
        lon = ipLocationRes.data.longitude;

      } catch (error) {
        console.log('Error Case 2 ' + error)
      }

    }





    let categoriasRes = {
      data: []
    }
    try {
      categoriasRes = await categories.searchCategoriesByParentId(parent_id)
    } catch (error) {
      categoriasRes = {
        data: []
      }
    }


    titleCategoriasDropDown = `${categoriaBuscada.name} Categories`;

    console.log(JSON.stringify('Categorias hijas: ' + categoriasRes.data.length))
    categoriasDropDown = categoriasRes.data.filter(function (category) {
      if (parent_id == 54) {
        if (category.id === "55" || category.id === "56") {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    });


    let categoriasMenu = categoriasRes.data.filter(function (category) {
      if (parent_id == 54) {
        if (
          category.slug === "rock-pop" ||
          category.slug === "alternative-rock" ||
          category.slug === "alternative-indie" ||
          category.slug === "country-folk" ||
          category.slug === "dance-electronic"
        ) {
          return true;
        } else {
          return false;
        }
      } else if (parent_id == 73) {
        if (
          category.slug === "conventions" ||
          category.slug === "miscellaneous-events" ||
          category.slug === "attractions"
        ) {
          return true;
        } else {
          return false;
        }
      } else if (parent_id == 1) {
        if (
          category.slug === "football" ||
          category.slug === "baseball" ||
          category.slug === "basketball"
        ) {
          return true;
        } else {
          return false;
        }
      } else if (parent_id == 19) {
        //football
        if (
          category.slug === "nfl" ||
          category.slug === "ufl" ||
          category.slug === "afl" ||
          category.slug === "ifl" ||
          category.slug === "ncaa-football"
        ) {
          return true;
        } else {
          return false;
        }
      }
      if (parent_id == 2) {
        //baseball
        if (
          category.slug === "mlb" ||
          category.slug === "ncaa" ||
          category.slug === "minor-league"
        ) {
          return true;
        } else {
          return false;
        }
      }
      if (parent_id == 7) {
        //basketball
        if (
          category.slug === "nba" ||
          category.slug === "ncaa-men-basketball" ||
          category.slug === "ncaa-women-basketball" ||
          category.slug === "wnba"
        ) {
          return true;
        } else {
          return false;
        }
      } else if (parent_id == 34) {
        if (
          category.slug === "english-premiere-league" ||
          category.slug === "mls" ||
          category.slug === "spanish-primera-division" ||
          category.slug === "bundesliga" ||
          category.slug === "world-cup"
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    });

    /**
     *
     * ====================
     * Categorias hermanas
     * ====================
     */
    if (
      categoriasRes.data.length == 0 &&
      !(category_id == 20 || category_id == 8 || category_id == 3)
    ) {
      try {
        categoriasRes = await categories.searchCategoriesByParentId(category.parent_id)
      } catch (error) {

      }
      if (category) {
        titleCategoriasDropDown = `${category.parent_name} Categories`;
      } else {
        console.log(`No tengo categoria ... titleCategoriasDropDown `)
      }


      categoriasDropDown = categoriasRes.data.filter(function (category) {
        if (parent_id == 54) {
          if (category.id === "55" || category.id === "56") {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      });
    }

    categoriasDropDown.sort(function (o1, o2) {
      if (o1.name > o2.name) {
        //comparación lexicogŕafica
        return 1;
      } else if (o1.name < o2.name) {
        return -1;
      }
      return 0;
    });

    categoriasMenu.sort(function (o1, o2) {
      if (o1.name > o2.name) {
        //comparación lexicogŕafica
        return 1;
      } else if (o1.name < o2.name) {
        return -1;
      }
      return 0;
    });


    let order_by_q = 'events.popularity_score DESC'
    if (order_by) {
      if (order_by == 'date') {
        order_by_q = 'events.occurs_at ASC'
      }
    }

    let query = {
      category_id: category_id,
      category_tree: true,
      //"only_with_available_tickets": "true",
      'occurs_at.gte': momentFunctions.occurs_at_gte(context.query.month),
      only_with_tickets: 'all',
      order_by: order_by_q, //events.occurs_at ASC events.popularity_score DESC"

      page: 1,
      per_page: 20,
      min_and_max_price: true

    };
    queryBase = query;
    let query2 = {
      category_id: category_id,
      category_tree: true,
      'occurs_at.gte': momentFunctions.occurs_at_gte(context.query.month),
      only_with_tickets: 'all',
      //"only_with_available_tickets": "true",
      order_by: order_by_q,//events.occurs_at ASC events.popularity_score DESC"

      page: 1,
      per_page: 20,
      min_and_max_price: true
    };

    if (lat && lon) {
      if (lat != 0 && lon != 0) {
        query2.lat = lat;
        query2.lon = lon;
        query2.within = 25;
      }
    }
    if (latC && lonC) {
      if (latC != 0 && lonC != 0) {
        query2.lat = latC;
        query2.lon = lonC;
        query2.within = 25;
      }
    }



    let eventos1 = []
    let eventos2 = []

    try {
      [eventos1, eventos2] = await Promise.all([
        //context.$axios.$get(`/tevo/events/query`, {  params: query  }),
        //context.$axios.$get(`/tevo/events/query`, {  params: query2  }),   
        events.actionGetEventTevoQuery(query),
        events.actionGetEventTevoQuery(query2)

      ]);
    } catch (error) {
      console.log('Error getting events in Categories Page')
      console.error(error)
    }


    let eventos = [];

    if (eventos2.length > 0) {

      eventos1 = eventos2;
      queryBase = query2;
      if (locality == "North America" && latC != 0 && latC != undefined) {
        cambiarLocality = true;
      }
    } else {

      cambiarLocality = false;
      locality = "North America";
      if (ciudadEncontrada == true) {
        //cambiarLocality =true
      }
    }

    if (category_id === "20") {
      /**
       * ==============================================
       * --------------NFL Conferencias
       * ==============================================
       */
      let aFCEast = eventos1.filter(function (evento) {
        if (
          evento.performances.performer.id === 15647 ||
          evento.performances.performer.id === 15649 ||
          evento.performances.performer.id === 15648 ||
          evento.performances.performer.id === 15646
        ) {
          return true;
        } else {
          return false;
        }
      });


      let newaFCEast = [];
      for (let i = 0; i < aFCEast.length; i++) {
        pushIfNew.pushIfNew(newaFCEast, aFCEast[i]);
      }



      //***************************************************/
      let aFCNorth = eventos1.filter(function (evento) {
        if (
          evento.performances.performer.id === 15652 ||
          evento.performances.performer.id === 15653 ||
          evento.performances.performer.id === 15651 ||
          evento.performances.performer.id === 15650
        ) {
          return true;
        } else {
          return false;
        }
      });


      let newaFCNorth = [];
      for (let i = 0; i < aFCNorth.length; i++) {
        pushIfNew.pushIfNew(newaFCNorth, aFCNorth[i]);
      }


      //***************************************************/

      let aFCSouth = eventos1.filter(function (evento) {
        if (
          evento.performances.performer.id === 15655 ||
          evento.performances.performer.id === 15656 ||
          evento.performances.performer.id === 15657 ||
          evento.performances.performer.id === 15654
        ) {
          return true;
        } else {
          return false;
        }
      });


      let newaFCSouth = [];
      for (let i = 0; i < aFCSouth.length; i++) {
        pushIfNew.pushIfNew(newaFCSouth, aFCSouth[i]);
      }

      //***************************************************/

      let aFCWest = eventos1.filter(function (evento) {
        if (
          evento.performances.performer.id === 15660 ||
          evento.performances.performer.id === 15661 ||
          evento.performances.performer.id === 15659 ||
          evento.performances.performer.id === 15658
        ) {
          return true;
        } else {
          return false;
        }
      });


      let newaFCWest = [];
      for (let i = 0; i < aFCWest.length; i++) {
        pushIfNew.pushIfNew(newaFCWest, aFCWest[i]);
      }


      //***************************************************/

      let nFCEast = eventos1.filter(function (evento) {
        if (
          evento.performances.performer.id === 15662 ||
          evento.performances.performer.id === 15664 ||
          evento.performances.performer.id === 15663 ||
          evento.performances.performer.id === 15665
        ) {
          return true;
        } else {
          return false;
        }
      });


      let newnFCEast = [];
      for (let i = 0; i < nFCEast.length; i++) {
        pushIfNew.pushIfNew(newnFCEast, nFCEast[i]);
      }


      //***************************************************/

      let nFCNorth = eventos1.filter(function (evento) {
        if (
          evento.performances.performer.id === 15667 ||
          evento.performances.performer.id === 15666 ||
          evento.performances.performer.id === 15669 ||
          evento.performances.performer.id === 15668
        ) {
          return true;
        } else {
          return false;
        }
      });


      let newnFCNorth = [];
      for (let i = 0; i < nFCNorth.length; i++) {
        pushIfNew.pushIfNew(newnFCNorth, nFCNorth[i]);
      }


      //***************************************************/

      let nFCSouth = eventos1.filter(function (evento) {
        if (
          evento.performances.performer.id === 15670 ||
          evento.performances.performer.id === 15672 ||
          evento.performances.performer.id === 15673 ||
          evento.performances.performer.id === 15671
        ) {
          return true;
        } else {
          return false;
        }
      });


      let newnFCSouth = [];
      for (let i = 0; i < nFCSouth.length; i++) {
        pushIfNew.pushIfNew(newnFCSouth, nFCSouth[i]);
      }


      //***************************************************/

      let nFCWest = eventos1.filter(function (evento) {
        if (
          evento.performances.performer.id === 15675 ||
          evento.performances.performer.id === 15674 ||
          evento.performances.performer.id === 29838 ||
          evento.performances.performer.id === 15676
        ) {
          return true;
        } else {
          return false;
        }
      });


      let newnFCWest = [];
      for (let i = 0; i < nFCWest.length; i++) {
        pushIfNew.pushIfNew(newnFCWest, nFCWest[i]);
      }


      //***************************************************/

      nflConferencias.push({
        nflFlat: "AFC",
        name: "AFC East",
        eventos: newaFCEast
      });

      nflConferencias.push({
        nflFlat: "AFC",
        name: "AFC North",
        eventos: newaFCNorth
      });

      nflConferencias.push({
        nflFlat: "AFC",
        name: "AFC South",
        eventos: newaFCSouth
      });

      nflConferencias.push({
        nflFlat: "AFC",
        name: "AFC West",
        eventos: newaFCWest
      });

      nflConferencias.push({
        nflFlat: "NFC",
        name: "NFC East",
        eventos: newnFCEast
      });

      nflConferencias.push({
        nflFlat: "NFC",
        name: "NFC North",
        eventos: newnFCNorth
      });

      nflConferencias.push({
        nflFlat: "NFC",
        name: "NFC South",
        eventos: newnFCSouth
      });

      nflConferencias.push({
        nflFlat: "NFC",
        name: "NFC West",
        eventos: newnFCWest
      });
    } else if (category_id === "8") {
      /**
       * ==============================================
       * -------------- NBA Conferencias --------------
       * ==============================================
       */
      let atlantic = eventos1.filter(function (evento) {
        if (
          evento.performances.performer.id === 16303 ||
          evento.performances.performer.id === 16301 ||
          evento.performances.performer.id === 16305 ||
          evento.performances.performer.id === 16302 ||
          evento.performances.performer.id === 16304
        ) {
          return true;
        } else {
          return false;
        }
      });


      let newatlantic = [];
      for (let i = 0; i < atlantic.length; i++) {
        pushIfNew.pushIfNew(newatlantic, atlantic[i]);
      }

      let central = eventos1.filter(function (evento) {
        if (
          evento.performances.performer.id === 16307 ||
          evento.performances.performer.id === 16310 ||
          evento.performances.performer.id === 16308 ||
          evento.performances.performer.id === 16306 ||
          evento.performances.performer.id === 16309
        ) {
          return true;
        } else {
          return false;
        }
      });


      let newcentral = [];
      for (let i = 0; i < central.length; i++) {
        pushIfNew.pushIfNew(newcentral, central[i]);
      }

      let southeast = eventos1.filter(function (evento) {
        if (
          evento.performances.performer.id === 16312 ||
          evento.performances.performer.id === 16313 ||
          evento.performances.performer.id === 16315 ||
          evento.performances.performer.id === 16314 ||
          evento.performances.performer.id === 16311
        ) {
          return true;
        } else {
          return false;
        }
      });


      let newsoutheast = [];
      for (let i = 0; i < southeast.length; i++) {
        pushIfNew.pushIfNew(newsoutheast, southeast[i]);
      }

      let northwest = eventos1.filter(function (evento) {
        if (
          evento.performances.performer.id === 16316 ||
          evento.performances.performer.id === 16325 ||
          evento.performances.performer.id === 16322 ||
          evento.performances.performer.id === 16323 ||
          evento.performances.performer.id === 16324
        ) {
          return true;
        } else {
          return false;
        }
      });


      let newnorthwest = [];
      for (let i = 0; i < northwest.length; i++) {
        pushIfNew.pushIfNew(newnorthwest, northwest[i]);
      }

      let pacific = eventos1.filter(function (evento) {
        if (
          evento.performances.performer.id === 16326 ||
          evento.performances.performer.id === 16327 ||
          evento.performances.performer.id === 16330 ||
          evento.performances.performer.id === 16329 ||
          evento.performances.performer.id === 16328
        ) {
          return true;
        } else {
          return false;
        }
      });


      let newnpacific = [];
      for (let i = 0; i < pacific.length; i++) {
        pushIfNew.pushIfNew(newnpacific, pacific[i]);
      }

      let southwest = eventos1.filter(function (evento) {
        if (
          evento.performances.performer.id === 16317 ||
          evento.performances.performer.id === 16319 ||
          evento.performances.performer.id === 16320 ||
          evento.performances.performer.id === 16318 ||
          evento.performances.performer.id === 16321
        ) {
          return true;
        } else {
          return false;
        }
      });


      let newsouthwest = [];
      for (let i = 0; i < southwest.length; i++) {
        pushIfNew.pushIfNew(newsouthwest, southwest[i]);
      }

      nflConferencias.push({
        nflFlat: "Eastern",
        name: "Atlantic",
        eventos: newatlantic
      });

      nflConferencias.push({
        nflFlat: "Eastern",
        name: "Central",
        eventos: newcentral
      });

      nflConferencias.push({
        nflFlat: "Eastern",
        name: "South East",
        eventos: newsoutheast
      });
      //----
      nflConferencias.push({
        nflFlat: "Western",
        name: "North West",
        eventos: newnorthwest
      });

      nflConferencias.push({
        nflFlat: "Western",
        name: "Pacific",
        eventos: newnpacific
      });
      nflConferencias.push({
        nflFlat: "Western",
        name: "South West",
        eventos: newsouthwest
      });
      for (let i = 0; i < eventos1.length; i++) {
        pushIfNew.pushIfNew(eventos, eventos1[i]);
      }
    } else if (category_id === "3") {
      /**
       * ==============================================
       * -------------- MLB Conferencias --------------
       * ==============================================
       */
      let alCentral = eventos1.filter(function (evento) {
        if (
          evento.performances.performer.id === 15539 ||
          evento.performances.performer.id === 15537 ||
          evento.performances.performer.id === 15538 ||
          evento.performances.performer.id === 15536 ||
          evento.performances.performer.id === 15540
        ) {
          return true;
        } else {
          return false;
        }
      });


      let newalCentral = [];
      for (let i = 0; i < alCentral.length; i++) {
        pushIfNew.pushIfNew(newalCentral, alCentral[i]);
      }

      nflConferencias.push({
        nflFlat: "American League",
        name: "AL Central",
        eventos: newalCentral
      });

      let alEast = eventos1.filter(function (evento) {
        if (
          evento.performances.performer.id === 15535 ||
          evento.performances.performer.id === 15532 ||
          evento.performances.performer.id === 15533 ||
          evento.performances.performer.id === 15534 ||
          evento.performances.performer.id === 16425
        ) {
          return true;
        } else {
          return false;
        }
      });


      let newalEast = [];
      for (let i = 0; i < alEast.length; i++) {
        pushIfNew.pushIfNew(newalEast, alEast[i]);
      }

      nflConferencias.push({
        nflFlat: "American League",
        name: "AL East",
        eventos: newalEast
      });

      let alWest = eventos1.filter(function (evento) {
        if (
          evento.performances.performer.id === 15544 ||
          evento.performances.performer.id === 15542 ||
          evento.performances.performer.id === 15541 ||
          evento.performances.performer.id === 15552 ||
          evento.performances.performer.id === 15543
        ) {
          return true;
        } else {
          return false;
        }
      });


      let newalWest = [];
      for (let i = 0; i < alWest.length; i++) {
        pushIfNew.pushIfNew(newalWest, alWest[i]);
      }

      nflConferencias.push({
        nflFlat: "American League",
        name: "AL West",
        eventos: newalWest
      });

      /**
       *
       *
       */
      let nlCentral = eventos1.filter(function (evento) {
        if (
          evento.performances.performer.id === 15550 ||
          evento.performances.performer.id === 15554 ||
          evento.performances.performer.id === 15553 ||
          evento.performances.performer.id === 15551 ||
          evento.performances.performer.id === 15555
        ) {
          return true;
        } else {
          return false;
        }
      });


      let newnlCentral = [];
      for (let i = 0; i < nlCentral.length; i++) {
        pushIfNew.pushIfNew(newnlCentral, nlCentral[i]);
      }

      nflConferencias.push({
        nflFlat: "National League",
        name: "NL West",
        eventos: newnlCentral
      });

      /**
       *
       *
       */
      let nlEast = eventos1.filter(function (evento) {
        if (
          evento.performances.performer.id === 15545 ||
          evento.performances.performer.id === 15546 ||
          evento.performances.performer.id === 15548 ||
          evento.performances.performer.id === 15549 ||
          evento.performances.performer.id === 15547
        ) {
          return true;
        } else {
          return false;
        }
      });


      let newnlEast = [];
      for (let i = 0; i < nlEast.length; i++) {
        pushIfNew.pushIfNew(newnlEast, nlEast[i]);
      }

      nflConferencias.push({
        nflFlat: "National League",
        name: "NL East",
        eventos: newnlEast
      });

      /**
       *
       *
       */
      let nlWest = eventos1.filter(function (evento) {
        if (
          evento.performances.performer.id === 15560 ||
          evento.performances.performer.id === 15556 ||
          evento.performances.performer.id === 15557 ||
          evento.performances.performer.id === 15559 ||
          evento.performances.performer.id === 15558
        ) {
          return true;
        } else {
          return false;
        }
      });


      let newnlWest = [];
      for (let i = 0; i < nlWest.length; i++) {
        pushIfNew.pushIfNew(newnlWest, nlWest[i]);
      }

      nflConferencias.push({
        nflFlat: "National League",
        name: "NL West",
        eventos: newnlWest
      });
    }

    for (let i = 0; i < eventos1.length; i++) {
      pushIfNew.pushIfNew(eventos, eventos1[i]);
    }

    if (cambiarLocality == true && eventos[0] != undefined) {
      if (eventos[0].venue != undefined) {
        if (eventos[0].venue.location != undefined) {
          locality = eventos[0].venue.location;
        }
      }
    }
    if (categoriaBuscada)
      if (categoriaBuscada.bannerImageUrl != undefined) {

        bannerImageUrl = category.bannerImageUrl;
        if (categoriaBuscada.bannerImageUrlMobil != undefined) {
          bannerImageUrlMobil = categoriaBuscada.bannerImageUrlMobil;
          if (context.isMobile) {
            bannerImageUrl = bannerImageUrlMobil;
          }
        }
        if (categoriaBuscada.bannerImageUrlTablet != undefined) {
          bannerImageUrlTablet = categoriaBuscada.bannerImageUrlTablet;
          if (context.isTablet) {
            bannerImageUrl = bannerImageUrlTablet;
          }
        }
      } else {
        let categoryP = {
          data: []
        }
        let categoryPP = {
          data: []
        }

        try {
          [categoryP, categoryPP] = await Promise.all([
            categories.searchCategoriesById(categoriaBuscada.parent_id),
            categories.searchCategoriesById(categoriaBuscada.parent_parent_id)
          ]);
          categoryP = categoryP.data[0];
          categoryPP = categoryPP.data[0];
        } catch (error) {

        }



        if (categoryP != undefined && categoryP.bannerImageUrl != undefined) {
          bannerImageUrl = categoryP.bannerImageUrl;

          if (categoryP.bannerImageUrlMobil != undefined) {
            bannerImageUrlMobil = categoryP.bannerImageUrlMobil;
            if (context.isMobile) {
              bannerImageUrl = bannerImageUrlMobil;
            }
          }
          if (categoryP.bannerImageUrlTablet != undefined) {
            bannerImageUrlTablet = categoryP.bannerImageUrlTablet;
            if (context.isTablet) {
              bannerImageUrl = bannerImageUrlTablet;
            }
          }
        } else {
          if (
            categoryPP != undefined &&
            categoryPP.bannerImageUrl != undefined
          ) {
            bannerImageUrl = categoryPP.bannerImageUrl;

            if (categoryPP.bannerImageUrlMobil != undefined) {
              bannerImageUrlMobil = categoryPP.bannerImageUrlMobil;
              if (context.isMobile) {
                bannerImageUrl = bannerImageUrlMobil;
              }
            }
          }
        }
      }
    let structuredData = {}
    let loadClass = {
      loadingClass: ''
    }
    try {
      structuredData = microformatos.getEventsStructuredData(eventos);
      loadClass = events.loadingClassF(eventos);
    } catch (error) {

    }



    return {
      categoriaBuscada: categoriaBuscada,
      queryBase: queryBase,
      loadingClass: loadClass.loadingClass,
      bannerImageUrl: bannerImageUrl,
      bannnerImageUrlDesktop: bannerImageUrl,
      bannerImageUrlMobil: bannerImageUrlMobil,
      nflConferencias: nflConferencias,
      category_id: category_id,
      parent_slug: parent_slug,
      categoriasDropDown: categoriasDropDown,
      titleCategoriasDropDown,
      categoriasMenu: categoriasMenu,
      bannerTitulo: bannerTitulo,
      bannerSlug: bannerSlug,
      showCategorias: showCategorias,
      dropdown_no_mobile: dropdown_no_mobile,
      categoriaTitulo: categoriaTitulo,
      structuredData: structuredData,
      eventos: eventos,
      pageImage: `${appFrontUrl}img/pepper_tix_share_events.jpg`,
      locality: locality,
      isCreated: false,
      visto: visto,
      toogleIntroSplashHwi: toogleIntroSplashHwi,
      lat,
      lon
    };

  },
  async fetch({ store, params }) {
    //await store.dispatch("performers/searchPerformerByQuery", parametros);
    //await store.dispatch("performers/storePerformerByName", params.search);
  },
  head() {
    let keywords = "";
    let description = `Buy the best deals for upcoming ${this.categoriaTitulo} events at ${this.locality}`;

    let title = `${this.categoriaTitulo} Events | Pepper Tix`;

    let locality = ``;
    if (this.locality != "North America") {
      title = `${this.categoriaTitulo} Events at ${this.locality} | Pepper Tix`;
      locality = ` at ${locality}`;
    }
    if (this.categoriaBuscada)
      if (this.categoriaBuscada.meta)
        if (this.categoriaBuscada.meta.category != undefined) {
          if (
            this.categoriaBuscada.meta.category.description != undefined &&
            this.categoriaBuscada.meta.category.description.length >= 10
          ) {
            description = replaceAll(
              this.categoriaBuscada.meta.category.description,
              "{{category.name}}",
              this.categoriaBuscada.name
            );

            description = replaceAll(description, "{{locality}}", locality);
          }
        }

    return {
      title: title,
      meta: [
        {
          hid: "description",
          name: "description",
          content: description
        },
        {
          hid: "keywords",
          name: "keywords",
          content: keywords
        },

        {
          hid: "og:description",
          name: "og:description",
          content: description
        },

        {
          property: "og:url",
          content: `${appFrontUrl}`
        },
        {
          property: "og:type",
          content: "website"
        },
        {
          property: "og:title",
          content: title
        },
        {
          property: "og:image",
          content: `${appFrontUrl}img/pepper_tix_share_events.jpg`
        }
      ],
      __dangerouslyDisableSanitizers: ["script"],
      script: [
        {
          innerHTML: JSON.stringify(this.structuredData),
          type: "application/ld+json"
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
      isAutenticated: "user/isAutenticated",
      prospect: "user/prospect",
      media_match: "media_match",
      openTrackArtist: "openTrackArtist",
      activePromoCode: "checkout/activePromoCode",
      tooglePromoRegister: "tooglePromoRegister",
      userHasPromoCode: "user/userHasPromoCode",
      promoCodeForApply: "user/promoCodeForApply"
    })
  },
  name: "app",

  data() {
    return {
      showMenuCategorias: false,
      showCategoriasDropDown: true,
      showDivisionsButtons: false,
      tryForGetMoreEvents: true,
      showMore: true,
      changeLozad: false,
      parent_slug: "",
      showAll: true,
      conferencia1: "AFC",
      conferencia2: "NFC",
      showAFCButtonActive: false,
      showNFCButtonActive: false,
      nflConferenciasM: [],
      nflFlat: "",

      btnNombre: "Categories",
      categoriasMenu: [],
      categoriasDropDown: [],
      eventos: [],
      privacy_policy: "#",
      structuredData: {},
      showHWi: false,
      visto: true,
      isMounted: false,
      description: "Your home events tickets",
      appFrontUrl: appFrontUrl,
      invitation_code: this.user ? `?invitation_code=${this.user._id}` : "",
      prueba: "Home",

      cargando: true,
      showArtists: true,
      artists: [],
      transform: "",
      scroll_on_track_artist: false,
      //myArtists: [],
      isFavorite: true,
      locality: "",
      administrative_area_level_1: "",
      per_page: 12,
      page: 1,
      magic_flag: false,
      isModalVisible: false,
      position: {
        latitude: null,
        longitude: null
      },
      carrusel_per_page: 3,
      access_token: null,

      toogleSignUp: false,
      toogleSignIn: false,
      toogleIntroSplash: false,
      tevo5NextDaysCarrusel: {
        per_page: 4,
        eventos: []
      },
      tmByLocationCarrusel: {
        per_page: 4,
        eventos: []
      },
      chicago: {
        lat: 41.85003, //chicago
        lon: -87.65005 //chicago
      },
      vegas: {
        lat: 36.17497, //las vegas
        lon: -115.1372 //las vegas
      },
      sanfrancisco: {
        lat: 37.77493,
        lon: -122.41942
      },
      bogota: {
        lat: 4.624335,
        lon: -74.063644
      },
      nashville_TN: {
        lat: 36.174465,
        lon: -86.76796
      },
      newHeaven_CT: {
        lat: 41.308273,
        lon: -72.927887
      },
      buffalo_NY: {
        lat: 42.963947,
        lon: -78.737808
      },
      albany_NY: {
        lat: 42.65258,
        lon: -73.756233
      },
      austin_TX: {
        lat: 30.266926,
        lon: -97.750519
      }
    };
  },
  created() {
    this.nflConferenciasM = this.nflConferencias;

    if (this.category_id == 20) {
      this.conferencia1 = "AFC";
      this.conferencia2 = "NFC";
    } else if (this.category_id == 8) {
      this.conferencia1 = "Eastern";
      this.conferencia2 = "Western";
    } else if (this.category_id == 3) {
      this.conferencia1 = "American League";
      this.conferencia2 = "National League";
    }

    this.verifyFavoriteByEvent();
  },
  mounted() {
    this.scrollMe();

    this.$nextTick(async () => {
      this.$store.commit("checkout/selectedTicketGroup", null)



      let maxHeight = Math.max(
        window.innerHeight || 0,
        window.outerHeight || 0
      );
      let minHeight = Math.min(
        window.innerHeight || 0,
        window.outerHeight || 0
      );


      if (
        this.category_id == 20 ||
        this.category_id == 8 ||
        this.category_id == 3
      ) {
        this.btnNombre = "All";
        this.categoriasDropDown.push({ name: "All" });
        this.categoriasDropDown.push({ name: this.conferencia1 });
        this.categoriasDropDown.push({ name: this.conferencia2 });
      } else {
        this.btnNombre = "Categories";
      }

      //this.$lozad.observe();
      var vm = this;
      var hasScrollbar =
        window.innerHeight > document.documentElement.clientHeight;

      if (
        document.body.offsetHeight <= window.innerHeight ||
        this.eventos.length < 8
      ) {
        this.showMoreEventCards();
      }
      /*if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            vm.showPosition,
            vm.errorShowPosition
          );
        } else {
        }*/

      this.invitation_code = this.user
        ? `?invitation_code=${this.user._id}`
        : "";
      if (this.media_match === 0) {
        //primera screen and (max-width: 600px)
        document.getElementById(
          "contenedor_principal"
        ).style.minHeight = `${minHeight - 154}px`;

        this.dropdown_no_mobile = false;
        this.carrusel_per_page = 1;
        this.showDivisionsButtons = false;
        if (
          this.category_id == 20 ||
          this.category_id == 8 ||
          this.category_id == 3
        ) {
          this.showCategoriasDropDown = true;
        }
      } else if (this.media_match === 1) {
        //segunda screen and (min-width: 601px) and (max-width: 767px)

        this.carrusel_per_page = 2;
        this.dropdown_no_mobile = true;
        this.showDivisionsButtons = true;
        if (
          this.category_id == 20 ||
          this.category_id == 8 ||
          this.category_id == 3
        ) {
          this.showCategoriasDropDown = false;
        }
      } else if (this.media_match === 2) {
        //tercera screen and (min-width: 768px) and (max-width: 991px)
        this.carrusel_per_page = 2;
        this.dropdown_no_mobile = true;
        this.showDivisionsButtons = true;
        if (
          this.category_id == 20 ||
          this.category_id == 8 ||
          this.category_id == 3
        ) {
          this.showCategoriasDropDown = false;
        }
      } else if (this.media_match === 3) {
        //cuarta screen and (min-width: 992px) and (max-width: 1199px)
        this.carrusel_per_page = 3;
        this.dropdown_no_mobile = true;
        this.showDivisionsButtons = true;
        if (
          this.category_id == 20 ||
          this.category_id == 8 ||
          this.category_id == 3
        ) {
          this.showCategoriasDropDown = false;
        }
      } else if (this.media_match === 4) {
        //quinta screen and (min-width: 1200px)
        this.carrusel_per_page = 3;
        this.dropdown_no_mobile = true;
        this.showDivisionsButtons = true;
        if (
          this.category_id == 20 ||
          this.category_id == 8 ||
          this.category_id == 3
        ) {
          this.showCategoriasDropDown = false;
        }
      }
      window.addEventListener("scroll", this.scrollMe);
      this.$store.commit("storeCoordinates", { lat: this.lat, lon: this.lon });
      document.addEventListener("click", this.closeAllSelect);
      window.addEventListener("resize", this.windowResize);

      document.addEventListener('mousemove', this.initLoadEvents);
      document.addEventListener('touchstart', this.initLoadEvents);

    });
  },

  watch: {
    openTrackArtist: function (newVal) {

      if (newVal == true) {
        this.showModal();
      }
    },
    isAutenticated: function (newVal) {
      if (this.artists && this.artists.length > 0) {
        this.verifyFavorite();
      } else if (this.eventos && this.eventos.length > 0) {
        this.verifyFavoriteByEvent();
      }

      if (newVal === false) {
        this.clearFavorites()
      }
    },

    prospect: async function (newVal) {
      if (this.prospect === true) {
        this.clearFavorites()
      } else {
        if (this.isAutenticated) {
          if (this.artists && this.artists.length > 0) {
            this.verifyFavorite();
          } else if (this.eventos && this.eventos.length > 0) {
            this.verifyFavoriteByEvent();
          }
        }
      }
    },

    isModalVisible: function (newVal) {
      let className = "modal-open";

      if (newVal) {

      } else {

      }
    },
    showMenuCategorias: function (newVal) {
      let className = "modal-open";

      if (newVal) {

      } else {

      }
    },
    toogleSignIn: function (newVal) {
      let className = "modal-open";

      if (newVal) {

      } else {

        this.verifyFavoriteByEvent();
      }
    },

    media_match(newValue, oldValue) {
      if (newValue === 0) {
        //primera screen and (max-width: 600px)
        this.dropdown_no_mobile = false;
        this.carrusel_per_page = 1;
        this.showDivisionsButtons = false;
        if (
          this.category_id == 20 ||
          this.category_id == 8 ||
          this.category_id == 3
        ) {
          this.showCategoriasDropDown = true;
        }
        this.bannerImageUrl = this.bannerImageUrlMobil;

      } else if (newValue === 1) {
        //segunda screen and (min-width: 601px) and (max-width: 767px)
        this.dropdown_no_mobile = true;
        this.carrusel_per_page = 2;
        this.showDivisionsButtons = true;
        if (
          this.category_id == 20 ||
          this.category_id == 8 ||
          this.category_id == 3
        ) {
          this.showCategoriasDropDown = false;
        }
        if (this.bannerImageUrlTablet) {
          this.bannerImageUrl = this.bannerImageUrlTablet;
        } else {
          this.bannerImageUrl = this.bannnerImageUrlDesktop;
        }


      } else if (newValue === 2) {
        //tercera screen and (min-width: 768px) and (max-width: 991px)
        this.dropdown_no_mobile = true;
        this.carrusel_per_page = 2;
        this.showDivisionsButtons = true;
        if (
          this.category_id == 20 ||
          this.category_id == 8 ||
          this.category_id == 3
        ) {
          this.showCategoriasDropDown = false;
        }
        if (this.bannerImageUrlTablet) {
          this.bannerImageUrl = this.bannerImageUrlTablet;
        } else {
          this.bannerImageUrl = this.bannnerImageUrlDesktop;
        }

      } else if (newValue === 3) {
        //cuarta screen and (min-width: 992px) and (max-width: 1199px)
        this.dropdown_no_mobile = true;
        this.carrusel_per_page = 3;
        this.showDivisionsButtons = true;
        if (
          this.category_id == 20 ||
          this.category_id == 8 ||
          this.category_id == 3
        ) {
          this.showCategoriasDropDown = false;
        }
        this.bannerImageUrl = this.bannnerImageUrlDesktop;

      } else if (newValue === 4) {
        //quinta screen and (min-width: 1200px)
        this.dropdown_no_mobile = true;
        this.carrusel_per_page = 3;
        this.showDivisionsButtons = true;
        if (
          this.category_id == 20 ||
          this.category_id == 8 ||
          this.category_id == 3
        ) {
          this.showCategoriasDropDown = false;
        }
        this.bannerImageUrl = this.bannnerImageUrlDesktop;

      }
    }
  },
  methods: {

    initLoadEvents(event) {
      this.showMoreEventCards();
      //this.$gtm.init('GTM-P63HHPN')

      /*setTimeout(()=>{
       FB.XFBML.parse(); 
       FB?.CustomerChat?.hide();
     }, 500)*/

      /*facebook.facebook(true, ()=>{
        //FB.XFBML.parse();  
        FB?.CustomerChat?.hide();  
        console.log('ocultar chat')
      })*/

      event.currentTarget.removeEventListener(event.type, this.initLoadEvents); // remove the event listener that got triggered

    },


    clearFavorites() {
      for (let j = 0; j < this.eventos.length; j++) {
        this.eventos[j].isFavorite = false;
        Vue.set(this.eventos, j, this.eventos[j]);

      }
      for (let k = 0; k < this.nflConferencias.length; k++) {
        for (
          let j = 0;
          j < this.nflConferencias[k].eventos.length;
          j++
        ) {
          this.nflConferencias[k].eventos[j].isFavorite = false;

          Vue.set(this.nflConferencias, k, this.nflConferencias[k]);

        }
      }




      for (let k = 0; k < this.nflConferenciasM.length; k++) {
        for (
          let j = 0;
          j < this.nflConferenciasM[k].eventos.length;
          j++
        ) {
          this.nflConferenciasM[k].eventos[j].isFavorite = false;
          Vue.set(
            this.nflConferenciasM,
            k,
            this.nflConferenciasM[k]
          );
        }
      }


    },

    closePromoRegister() {
      this.$store.commit("tooglePromoRegister", false)

    },
    windowResize: function () {


      let maxHeight = Math.max(
        window.innerHeight || 0,
        window.outerHeight || 0
      );
      let minHeight = Math.min(
        window.innerHeight || 0,
        window.outerHeight || 0
      );


      if (this.media_match === 0) {
        //primera screen and (max-width: 600px)
        //brice aki
        document.getElementById(
          "contenedor_principal"
        ).style.minHeight = `${minHeight - 154}px`;
      }
    },
    getRuta(event) {
      return microformatos.getRuta(event);
    },
    async showMoreEventCards() {
      if (this.eventos.length >= 60)
        return;

      this.changeLozad = true;

      this.queryBase.page = this.queryBase.page + 1;
      this.queryBase.per_page = 100
      let eventos1 = []

      if (this.tryForGetMoreEvents === true) {


        try {
          eventos1 = await events.actionGetEventTevoQuery(this.queryBase)
        } catch (error) { }

      }

      for (let i = 0; i < eventos1.length; i++) {
        pushIfNew.pushIfNew(this.eventos, eventos1[i]);
      }

      if (eventos1.length > 0) {
        this.verifyFavoriteByEvent();
        let bottomOfWindow =
          document.documentElement.scrollTop + window.innerHeight ===
          document.documentElement.scrollHeight;
        if (bottomOfWindow) {


          let intervalo = setInterval(() => {
            this.showMoreEventCards();

            clearInterval(intervalo);
          }, 100);
        } else {
        }
      } else {

        this.tryForGetMoreEvents = false;
      }
    },

    scrollMe() {
      let scrollHeight = document.documentElement.scrollHeight;
      let scrollTop = document.documentElement.scrollTop;
      let innerHeight = window.innerHeight;

      let diferencia = scrollHeight - scrollTop - innerHeight;


      if (diferencia <= 150) {

        if (this.tryForGetMoreEvents === true) {
          this.showMoreEventCards();
        }
      } else {
      }
    },
    showAllEvents() {
      this.changeLozad = true;
      this.showAll = true;
      this.showAFCButtonActive = false;
      this.showNFCButtonActive = false;
    },
    showAFCTeams() {
      this.showAll = false;
      if (this.category_id == 20) {
        this.nflFlat = "AFC";
      } else if (this.category_id == 8) {
        this.nflFlat = "Eastern";
      } else if (this.category_id == 3) {
        this.nflFlat = "American League";
      }


      this.showAFCButtonActive = true;
      this.showNFCButtonActive = false;
      this.filtrarNflConferencias(this.nflFlat);
    },
    showNFCTeams() {
      this.showAll = false;
      if (this.category_id == 20) {
        this.nflFlat = "NFC";
      } else if (this.category_id == 8) {
        this.nflFlat = "Western";
      } else if (this.category_id == 3) {
        this.nflFlat = "National League";
      }
      this.showNFCButtonActive = true;
      this.showAFCButtonActive = false;
      this.filtrarNflConferencias(this.nflFlat);
    },
    filtrarNflConferencias(nflFlat) {
      this.nflConferenciasM = this.nflConferencias.filter(function (
        conferencia
      ) {
        if (conferencia.nflFlat == nflFlat) {
          return true;
        } else {
          return false;
        }
      });
    },
    goBack() {
      window.history.back();
    },
    onload() {

    },

    gotToCategory(category, index) {
      if (
        this.category_id == 20 ||
        this.category_id == 8 ||
        this.category_id == 3
      ) {

        switch (index) {
          case 0: {
            this.showAllEvents();
            break;
          }
          case 1: {
            this.showAFCTeams();
            break;
          }
          case 2: {
            this.showNFCTeams();
            break;
          }
        }

      } else {
        this.$router.push(`/${this.parent_slug}/${category.slug}`);
      }

      //window.location.href = `/concerts/${category.name}`;
    },

    show_categories: function (e) {
      this.showMenuCategorias = false;
      e.stopPropagation();
      var x = document.getElementsByClassName("select-selected");

      this.closeAllSelect(x[0]);
      if (this.dropdown_no_mobile == true) {
        x[0].nextSibling.nextSibling.classList.toggle("select-hide");
        x[0].classList.toggle("select-arrow-active");
      } else {
        let dropdown_mobile = document.getElementsByClassName(
          "dropdown_mobile"
        );
        dropdown_mobile[0].classList.toggle("select-hide");
        let select_items = dropdown_mobile[0].getElementsByClassName(
          "select-items"
        );
        dropdown_mobile[0]
          .getElementsByClassName("select-items")[0]
          .classList.toggle("select-hide");
        this.showMenuCategorias = true;
        //dropdown_mobile[0].classList.toggle("select-arrow-active");
      }
    },
    closeAllSelect: function (elmnt) {
      var x,
        y,
        i,
        arrNo = [];
      x = document.getElementsByClassName("select-items");
      y = document.getElementsByClassName("select-selected");

      for (i = 0; i < y.length; i++) {
        if (elmnt == y[i]) {
          arrNo.push(i);
        } else {
          y[i].classList.remove("select-arrow-active");
        }
      }

      for (i = 0; i < x.length; i++) {
        if (arrNo.indexOf(i)) {
          x[i].classList.add("select-hide");
        }
      }
    },
    item_div: function (evento, category, index) {

      this.gotToCategory(category, index);
      /*when an item is clicked, update the original select box,
              and the selected item:*/
      var y, i, k, s, h;
      s = evento.target.parentNode.parentNode.getElementsByTagName("select")[0];
      if (evento.target.parentNode.previousSibling) {
        h = evento.target.parentNode.previousSibling.previousSibling;
        for (i = 0; i < s.length; i++) {
          if (s.options[i].innerHTML == evento.target.innerHTML) {
            s.selectedIndex = i;
            h.innerHTML = evento.target.innerHTML;
            y = evento.target.parentNode.getElementsByClassName(
              "same-as-selected"
            );
            for (k = 0; k < y.length; k++) {
              //y[k].removeAttribute("class");
              y[k].setAttribute("class", "drop_options");
            }
            evento.target.setAttribute("class", "same-as-selected");
            break;
          }
        }
        h.click();
      }
    },
    handleScroll: function (event) {
      var x = document.getElementsByClassName("container_content");
      var y = document.getElementsByClassName("trackartist");
      var z = document.getElementsByClassName("floating_button_bottom");
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;
      var currentScrollPosition = event.target.scrollingElement.scrollTop;

      if (currentScrollPosition > 0) {
        this.scroll_on_track_artist = true;

        if (window.innerWidth <= 768) {
          if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            if (
              window.innerHeight + event.target.scrollingElement.scrollTop >
              document.body.clientHeight - 90
            ) {
              var calc_padding_bottom = Math.abs(
                window.innerHeight +
                event.target.scrollingElement.scrollTop -
                (document.body.clientHeight - 90)
              );
              var calc_margin_bottom =
                (16 + Math.ceil(calc_padding_bottom)) / 106;
              var margin_bottom = 0;
              if (calc_margin_bottom >= 1) {
                margin_bottom = 106;
              } else {
                margin_bottom = Math.ceil(16 + calc_padding_bottom);
              }

              z[0].style.margin = "0px 16px " + margin_bottom + "px 0px";
            } else {
              z[0].style.margin = "0px 16px 16px 0px";
            }
          } else {
            if (
              event.target.scrollingElement.clientHeight +
              event.target.scrollingElement.scrollTop >
              event.target.scrollingElement.scrollHeight - 90
            ) {
              var calc_padding_bottom = Math.abs(
                event.target.scrollingElement.clientHeight +
                event.target.scrollingElement.scrollTop -
                (event.target.scrollingElement.scrollHeight - 90)
              );
              z[0].style.margin =
                "0px 16px " + (16 + calc_padding_bottom) + "px 0px";
            } else {
              z[0].style.margin = "0px 16px 16px 0px";
            }
          }
        } else {
          if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            if (
              window.innerHeight + event.target.scrollingElement.scrollTop >
              document.body.clientHeight - 64
            ) {
              var calc_padding_bottom = Math.abs(
                window.innerHeight +
                event.target.scrollingElement.scrollTop -
                (document.body.clientHeight - 64)
              );
              var calc_margin_bottom = (16 + calc_padding_bottom) / 106;
              var margin_bottom = 0;
              if (calc_margin_bottom >= 1) {
                margin_bottom = 80;
              } else {
                margin_bottom = Math.ceil(16 + calc_padding_bottom);
              }
              z[0].style.margin = "0px 16px " + margin_bottom + "px 0px";
            } else {
              z[0].style.margin = "0px 16px 16px 0px";
            }
          } else {
            if (
              event.target.scrollingElement.clientHeight +
              event.target.scrollingElement.scrollTop >
              event.target.scrollingElement.scrollHeight - 64
            ) {
              var calc_padding_bottom = Math.abs(
                event.target.scrollingElement.clientHeight +
                event.target.scrollingElement.scrollTop -
                (event.target.scrollingElement.scrollHeight - 64)
              );
              z[0].style.margin =
                "0px 16px " + (16 + calc_padding_bottom) + "px 0px";
            } else {
              z[0].style.margin = "0px 16px 16px 0px";
            }
          }
        }

        if (window.innerWidth < 600) {
          y[0].innerHTML = "";
        } else {
          y[0].innerHTML = "";
        }
      } else {
        this.scroll_on_track_artist = false;
        y[0].innerHTML = "FOLLOW ARTISTS";
      }
    },
    hiwStart() {
      if (this.isAutenticated == false) {
        this.toogleSignIn = true;
      } else {
        this.isModalVisible = true;
      }
    },
    ClickBody() {
      this.$store.commit("ClickBody", true);
      this.showMenuCategorias = false;

    },
    facebookShareLink(name) {
      let share = `${appFrontUrl}/book/${name}`;
      return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        share
      )}&amp;src=sdkpreparse`;
    },
    fbShareDialog(name) {
      FB.ui(
        {
          method: "share",
          href: `${appFrontUrl}/book/${name}`
        },
        function (response) { }
      );
    },
    favoriteArtistClick(artist, index) {
      artist.isFavorite = !artist.isFavorite;

      let newArtists = {
        performer_id: artist.id,
        name: artist.name,
        images: [],
        popularity_score: artist.popularity_score,
        category_id: artist.category.id,
        category_name: artist.category.name,
        activado: true
      };

      let datos = {
        performers: [newArtists],
        category_id: 54
      };

      if (this.isAutenticated == false) {
        artist.isFavorite = false;
        this.toogleSignIn = true;
        this.$store.dispatch("user/setPerformersToSave", datos);
        return;
      } else {
      }

      if (artist.isFavorite == true) {
        this.$store
          .dispatch("user/setUserPerformers", datos)
          .then(response => {
            this.artists[index].isFavorite = true;
            Vue.set(this.artists, index, artist);

            //this.verifyFavorite();
          })
          .catch(error => {

          });
      } else {
        let borrar = [];

        borrar.push(artist);
        this.$store
          .dispatch("user/deleteUserSeleccionados", borrar)
          .then(response => {
            this.artists[index].isFavorite = false;
            Vue.set(this.artists, index, artist);


            this.verifyFavorite();
          })
          .catch(error => {

          });
      }
    },

    openSignIn() {
      this.$store.commit("signinform/signUpModePromo", false)
      this.$store.commit("signinform/toogleSignUp", false)
      this.$store.commit("userPromoAdded", false);
      this.$store.commit("showPromoToast", false)
      this.$store.commit("toogleSignIn", true)
      this.$store.commit("ClickBody", false)
      scroll.blockScroll(true)

    },

    /**
     * ===============
     * favoriteClick
     * ===============
     */
    favoriteClick(event, index) {

      let category_id = 0;
      if (event.category) {
        if (event.category.id) {

          if (event.category.parent) {

            if (event.category.parent.parent) {

              if (event.category.parent.parent.id) {

                category_id = event.category.parent.parent.id;
              } else {

                category_id = event.category.parent.id;
              }
            } else {

              category_id = event.category.parent.id;
            }
          } else {

            category_id = event.category.id;
          }
        }
      }


      let categoria_id = 0;
      let categoria_nombre = "";
      if (event.performances.performer.category) {
        categoria_id = event.performances.performer.category.id;
        categoria_nombre = event.performances.performer.category.name;
      }
      let newArtists = {
        performer_id: event.performances.performer.id,
        name: event.performances.performer.name,
        images: [],
        popularity_score: event.performances.performer.popularity_score,
        category_id: categoria_id,
        category_name: categoria_nombre,
        activado: true
      };

      let datos = {
        performers: [newArtists],
        category_id: category_id
      };


      if (this.isAutenticated == false || (this.isAutenticated == true && this.prospect == true)) {

        this.$store.dispatch("user/setPerformersToSave", datos);
        this.openSignIn()
        return event.isFavorite = false
      } else {
      }


      event.isFavorite = !event.isFavorite;

      this.eventos[index].isFavorite = event.isFavorite;

      //this.isFavorite = !this.isFavorite;

      if (event.isFavorite == true) {
        this.$store
          .dispatch("user/setUserPerformers", datos)
          .then(response => {


            this.verifyFavoriteByEvent();
          })
          .catch(error => {

          });
      } else {
        let borrar = [];

        borrar.push(newArtists);
        this.$store
          .dispatch("user/deleteUserSeleccionados", borrar)
          .then(response => {

            this.verifyNoFavoriteByEvent(event.performances.performer.id);
          })
          .catch(error => {

          });
      }
    },
    /**
     * ========================
     * verifyFavorite
     * ========================
     *
     */
    async verifyFavorite() {

      if (this.isAutenticated == false || this.prospect == true) {
        for (let j = 0; j < this.artists.length; j++) {
          this.artists[j].isFavorite = false;

          Vue.set(this.artists, j, this.artists[j]);
        }
        return
      }

      let artists = await this.$store.dispatch("user/myArtists")
      this.myArtists = artists;

      if (this.myArtists.length > 0 && this.artists.length > 0) {
        for (let i = 0; i < this.myArtists.length; i++) {
          for (let j = 0; j < this.artists.length; j++) {
            if (this.myArtists[i].performer_id == this.artists[j].id) {
              this.artists[j].isFavorite = true;

              Vue.set(this.artists, j, this.artists[j]);
            }
          }
        }
      }

    },

    /**
     * ========================
     * verifyFavoriteByEvent
     * ========================
     *
     */
    async verifyFavoriteByEvent() {
      if (this.isAutenticated == false || this.prospect == true) {

        if (this.eventos) {
          for (let j = 0; j < this.eventos.length; j++) {
            this.eventos[j].isFavorite = false;
            Vue.set(this.eventos, j, this.eventos[j]);
          }
        }

        if (this.nflConferencias && this.nflConferencias.length > 0) {
          for (let k = 0; k < this.nflConferencias.length; k++) {
            for (let j = 0; j < this.nflConferencias[k].eventos.length; j++) {
              this.nflConferencias[k].eventos[j].isFavorite = false;

              Vue.set(this.nflConferencias, k, this.nflConferencias[k]);
            }
          }
        }

        if (this.nflConferenciasM && this.nflConferenciasM.length > 0) {
          for (let k = 0; k < this.nflConferenciasM.length; k++) {
            for (let j = 0; j < this.nflConferenciasM[k].eventos.length; j++) {
              this.nflConferenciasM[k].eventos[j].isFavorite = false;

              Vue.set(this.nflConferenciasM, k, this.nflConferenciasM[k]);
            }
          }
        }

        return
      }
      await this.$store.dispatch("user/updateUser")
      let user = this.user;
      let artists = user.music;
      this.myArtists = artists;

      let sports = [];
      let theatre = [];
      let special_events = [];

      if (user.sports.length > 0) {
        sports = user.sports;
        this.myArtists = this.myArtists.concat(sports);
      }
      if (user.theatre.length > 0) {
        theatre = user.theatre;
        this.myArtists = this.myArtists.concat(theatre);
      }
      if (user.special_events.length > 0) {
        special_events = user.special_events;
        this.myArtists = this.myArtists.concat(special_events);
      }

      if (this.myArtists.length > 0 && this.eventos.length > 0) {
        for (let i = 0; i < this.myArtists.length; i++) {
          for (let j = 0; j < this.eventos.length; j++) {
            if (
              this.myArtists[i].performer_id ==
              this.eventos[j].performances.performer.id
            ) {
              this.eventos[j].isFavorite = true;
              Vue.set(this.eventos, j, this.eventos[j]);
            }
          }
        }
      }

      if (this.myArtists.length > 0 && this.nflConferencias.length > 0) {

        for (let i = 0; i < this.myArtists.length; i++) {
          for (let k = 0; k < this.nflConferencias.length; k++) {
            for (
              let j = 0;
              j < this.nflConferencias[k].eventos.length;
              j++
            ) {
              if (
                this.myArtists[i].performer_id ==
                this.nflConferencias[k].eventos[j].performances
                  .performer.id
              ) {
                this.nflConferencias[k].eventos[j].isFavorite = true;

                Vue.set(this.nflConferencias, k, this.nflConferencias[k]);
              }
            }
          }
        }
      }

      if (this.myArtists.length > 0 && this.nflConferenciasM.length > 0) {

        for (let i = 0; i < this.myArtists.length; i++) {
          for (let k = 0; k < this.nflConferenciasM.length; k++) {
            for (
              let j = 0;
              j < this.nflConferenciasM[k].eventos.length;
              j++
            ) {
              if (
                this.myArtists[i].performer_id ==
                this.nflConferenciasM[k].eventos[j].performances
                  .performer.id
              ) {
                this.nflConferenciasM[k].eventos[j].isFavorite = true;

                Vue.set(
                  this.nflConferenciasM,
                  k,
                  this.nflConferenciasM[k]
                );
              }
            }
          }
        }
      }

    },

    /**
     * ========================
     * verifyNoFavoriteByEvent
     * ========================
     *
     */
    verifyNoFavoriteByEvent(performer_id) {
      if (this.isAutenticated == true) {
        for (let j = 0; j < this.eventos.length; j++) {
          if (performer_id == this.eventos[j].performances.performer.id) {
            this.eventos[j].isFavorite = false;
            Vue.set(this.eventos, j, this.eventos[j]);
          }
        }

        if (this.nflConferencias && this.nflConferencias.length > 0) {
          for (let k = 0; k < this.nflConferencias.length; k++) {
            for (let j = 0; j < this.nflConferencias[k].eventos.length; j++) {
              if (
                performer_id ==
                this.nflConferencias[k].eventos[j].performances.performer.id
              ) {
                this.nflConferencias[k].eventos[j].isFavorite = false;
                Vue.set(this.nflConferencias, k, this.nflConferencias[k]);
              }
            }
          }
        }

        if (this.nflConferenciasM && this.nflConferenciasM.length > 0) {
          for (let k = 0; k < this.nflConferenciasM.length; k++) {
            for (let j = 0; j < this.nflConferenciasM[k].eventos.length; j++) {
              if (
                performer_id ==
                this.nflConferenciasM[k].eventos[j].performances.performer.id
              ) {
                this.nflConferenciasM[k].eventos[j].isFavorite = false;
                Vue.set(this.nflConferenciasM, k, this.nflConferenciasM[k]);
              }
            }
          }
        }
      }
    },
    performerData() {
      this.cargando = true;
      this.eventos = [];
      this.showArtists = true;

      this.locality = "North America";

      let performers = this.morePopularPerformers;

      this.cargando = false;
      for (let i = 0; i < performers.length; i++) {
        performers[i].performer_id = performers[i].id;
        performers[i].activado = false;
        performers[i].isFavorite = false;

        if (i === performers.length - 1) {
          this.artists = performers;
          this.morePopularPerformers = this.artists;
          this.verifyFavorite();
        }
      }

      for (let i = 0; i < this.artists.length; i++) {
        this.artists[i].venue = {
          location: "Searching event",
          name: "Searching event"
        };
        this.artists[i].occurs_at_format = "Searching event";

        if (
          this.artists[i].imageUrl ===
          "https://ticketdelivery.herokuapp.com/images/no_artist_images_300x300.jpg"
        ) {


          this.upatePerformer(this.artists[i]);
        }

        this.getFirstEventByPerformer(this.artists[i], i);
      }
    },



    upatePerformer(event) {

      //let performer_id = event.performances.performer.id;

      let performer_id = event.id;


      this.$store
        .dispatch("performers/actionUpdatePerformerById", {
          performer_id
        })
        .then(response => {

          event.imageUrl = response.data.imageUrl;
        });
    },
    eventsTicketMaster() {
      let query = `page=0&size=8&lat=${this.position.latitude}&lon=${this.position.longitude}&radius=300`;

      this.$store
        .dispatch("events/actionGetEventTicketMasterQuery", query)
        .then(response => {
          let page = response.data.page;
          let eventos = response.data.eventos;

          this.tmByLocationCarrusel.eventos = eventos;
        });
    },
    /**
     * ======================================================================================
     * @description función ejecutada cuando no se tiene acceso a las posición del usuario
     * ======================================================================================
     */
    errorShowPosition(err) {

      this.performerData();
    },
    /**
     * ======================================================================================
     * @description funcion que se ejecuta  para obtener la position geográfica showPosition
     * ======================================================================================
     */
    showPosition(position) {
      var vm = this;

      vm.position.latitude = position.coords.latitude;
      vm.position.longitude = position.coords.longitude;


      /*this.position.latitude = this.sanfrancisco.lat;
            this.position.longitude = this.sanfrancisco.lon;*/

      /*this.position.latitude = this.nashville_TN.lat;
            this.position.longitude = this.nashville_TN.lon;*/

      /*this.position.latitude = this.newHeaven_CT.lat;
            this.position.longitude = this.newHeaven_CT.lon;

            this.position.latitude = this.buffalo_NY.lat;
                  this.position.longitude = this.buffalo_NY.lon;

                  this.position.latitude = this.albany_NY.lat;
                  this.position.longitude = this.albany_NY.lon;

                  this.position.latitude = this.austin_TX.lat;
                  this.position.longitude = this.austin_TX.lon;*/

      /*this.$store
                                                                                                                                .dispatch("coordinatesToCity", {
                                                                                                                                  lat: vm.position.latitude,
                                                                                                                                  lon: vm.position.longitude
                                                                                                                                })
                                                                                                                                .then(data => {
                                                                                                                                 
                                                                                                                                  this.locality = data.locality;
                                                                                                                                  this.administrative_area_level_1 = data.administrative_area_level_1;
                                                                                                                                  this.country = data.country;
                                                                                                                                })
                                                                                                                                .catch(error => {
                                                                                                                                  
                                                                                                                                });*/

      if (this.position.latitude && this.position.longitude) {
        let search = {
          lat: this.position.latitude,
          lon: this.position.longitude,
          page: 1,
          per_page: 54
        };

        this.$store
          .dispatch("events/actionGetEventDuringTheNext5DaysByLocation", search)
          .then(response => {
            this.cargando = false;
            if (response.data.length > 0) {
              let tempo = [];

              for (let i = 0; i < response.data.length; i++) {
                pushIfNew.pushIfNew(tempo, response.data[i]);
              }

              this.eventos = tempo;
              this.verifyFavoriteByEvent();

              if (
                this.eventos[0] &&
                this.eventos[0].venue &&
                this.eventos[0].venue.location
              ) {
                this.locality = this.eventos[0].venue.location;
              }

              for (let i = 0; i < this.eventos.length; i++) {
                if (
                  this.eventos[i].imageUrl ===
                  "https://ticketdelivery.herokuapp.com/images/no_artist_images_300x300.jpg" ||
                  this.eventos[i].imageUrl == undefined
                ) {
                  this.eventos[i].imageUrl =
                    "https://ticketdelivery.herokuapp.com/images/no_artist_images_300x300.jpg";
                  this.upatePerformer(this.eventos[i]);
                }
              }
            } else {
              this.performerData();
            }
          });
      } else {
        this.performerData();
      }
    },

    showModal() {
      const el = document.body;
      el.classList.add("modal-open");
      this.isModalVisible = true;
    },
    closeModal() {
      const el = document.body;
      el.classList.remove("modal-open");
      this.isModalVisible = false;
    },
    alertaCordenadas() {
      alert(`lat ${this.position.latitude} lon ${this.position.longitude}`);
    },

    mediaQueries(alias, matches, init = false) {

      if (alias === "primera" && matches) {
        this.dropdown_no_mobile = false;
        this.carrusel_per_page = 1;
      } else if (alias === "segunda" && matches) {
        this.carrusel_per_page = 2;
        this.dropdown_no_mobile = false;
      } else if (alias === "tercera" && matches) {
        this.carrusel_per_page = 3;
      } else if (alias === "cuarta" && matches) {
        this.carrusel_per_page = 3;
      }
    },
    closeSignUp() {
      this.toogleSignUp = false;
    },
    openSignUp() {
      this.toogleSignUp = true;
    },
    closeSignIn() {
      this.toogleSignIn = false;
    },
    closeIntroSplash() {
      this.toogleIntroSplash = false;
    },
    closeIntroSplashHwi() {
      this.toogleIntroSplashHwi = false;
    },
    openSignIn() {
      this.toogleSignIn = true;
    },
    errorArtistImage(artist, index) {
      this.artists[index].imageUrl =
        "https://ticketdelivery.herokuapp.com/images/no_artist_images_300x300.jpg";
    },
    errorEventImage(artist, index) {

    }
  },
  destroyed: function () {
    window.removeEventListener("scroll", this.scrollMe);
    window.removeEventListener("resize", this.windowResize);
  }
};
